import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {FormattedMessage } from 'react-intl';
import heroImage from '../images/header/o_nama.svg'

const NotFoundPage = ({pageContext: { locale }}) => (
  <Layout
    locale={locale}
    heroImage={heroImage}
    title={<FormattedMessage id="404NotFound"/>}
    is404Page
  >
    <SEO title="404: Stranica nije pronadjena" />
  </Layout>
)

export default NotFoundPage
